import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    useToast,
    Tooltip,
} from '@chakra-ui/react'
import { HorarioLaboralSelect } from '../Customs/HorarioSelect/HorarioLaboralSelect';
import { DiaTypeEnum, JornadaLaboral } from '../../utils/Types/HorarioLaboral';
import { useEffect, useState } from 'react';
import { format, intervalToDuration } from 'date-fns';
import { StatusEnum, notify } from '../../utils/functions/notify';
import { updateHorarioLaboral } from '../../middlewares/users.middleware';
import { useAuthContex } from '../../context/user.context';
import { getHorasTotales } from '../../utils/functions/getHorasTotales';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const UserDisponibilidadData: JornadaLaboral = {
    [DiaTypeEnum.LUNES]: [],
    [DiaTypeEnum.MARTES]: [],
    [DiaTypeEnum.MIERCOLES]: [],
    [DiaTypeEnum.JUEVES]: [],
    [DiaTypeEnum.VIERNES]: [],
    [DiaTypeEnum.SABADO]: [],
    [DiaTypeEnum.DOMINGO]: []
};

export const ModalFundae = ({ isOpen, onClose }: Props) => {
    const { refreshUser } = useAuthContex();
    const toast = useToast();
    const [jornadaLaboral, setJornadaLaboral] = useState<JornadaLaboral | undefined>(UserDisponibilidadData)
    const [horasTotal, setHorasTotal] = useState<number>(0);
    const [isChecked, setIsChecked] = useState<boolean>(false);


    const validateCompletData = (jornadaLaboral: JornadaLaboral | undefined) => {
        if (!jornadaLaboral) return false

        const dias: DiaTypeEnum[] = [
            DiaTypeEnum.LUNES,
            DiaTypeEnum.MARTES,
            DiaTypeEnum.MIERCOLES,
            DiaTypeEnum.JUEVES,
            DiaTypeEnum.VIERNES,
            DiaTypeEnum.SABADO,
            DiaTypeEnum.DOMINGO
        ]

        for (let i = 0; i < dias?.length; i++) {
            if (jornadaLaboral[dias[i]]?.length > 0) return true
        }
    }

    useEffect(() => {
        if (jornadaLaboral) {
            const horasTotales = getHorasTotales(jornadaLaboral)
            setHorasTotal(horasTotales)
        }
    }, [jornadaLaboral])

    const handleSubmit = () => {
        if (jornadaLaboral === undefined || horasTotal === undefined) return notify(toast, StatusEnum.info, "Debe completar todos los campos")
        else if (horasTotal === 0) return notify(toast, StatusEnum.info, "Debe completar las horas semanales")
        else if (!validateCompletData(jornadaLaboral)) return notify(toast, StatusEnum.info, "Debe completar su jornada laboral")

        updateHorarioLaboral({
            horarioLaboral: jornadaLaboral
        })

            .then(() => {
                notify(toast, StatusEnum.success, "Su horario se guardo correctamente")
                refreshUser({
                    horarioLaboral: jornadaLaboral
                })
            })
            .finally(() => onClose())
    }


    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="3xl"
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent
                bg="purewhite"
                p="30px"
            >

                <ModalBody>
                    <HorarioLaboralSelect
                        label="Complete su jornada laboral"
                        sublabel="Hola! Te damos la bienvenida a este curso realizado a través de FUNDAE, necesitamos saber tu horario laboral antes de comenzar, no dudes en contactar con nosotros para cualquier consulta, gracias."
                        onChangeSelect={setJornadaLaboral}
                        defaultValueSelect={jornadaLaboral}
                        setIsChecked={setIsChecked}
                        isChecked={isChecked}
                        horasTotales={horasTotal}
                    />
                </ModalBody>

                <ModalFooter>
                    <Tooltip
                        label={horasTotal > 40
                            ? "El total de horas de tu jornada es más de 40h contacta con tu supervisor."
                            : "Debe confirmar el total de horas."
                        }
                        isDisabled={isChecked && horasTotal < 40}
                    >
                        <Button
                            p="10px 15px"
                            h="fit-content"
                            w="fit-content"
                            bg="main"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={handleSubmit}
                            isDisabled={!isChecked || horasTotal > 40}
                        >
                            Confirmar
                        </Button>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import { Flex, Progress, Image, Text, Box, Button } from "@chakra-ui/react";
import "./TablaCursos.scss";
import { truncate } from "lodash";
import { EstadoTypeEnum } from "../../../../shared/utils/Types/EstadoTypeEnum";

interface Props {
	matriculas: any;
}

export default function TablaCursos({ matriculas }: Props) {

	console.log(matriculas)

	return (
		<Flex
			flex="1"
		>
			<Flex
				w="100%"

				direction="column"
				flex="1"
			>
				<Flex
					w="100%"
				>
					<Text w="40%" fontFamily="inter" fontWeight="400" fontSize="16px" color="#959596" >CURSO</Text>
					<Text w="15%" fontFamily="inter" fontWeight="400" fontSize="16px" color="#959596" >ESTADO</Text>
					<Text w="20%" fontFamily="inter" fontWeight="400" fontSize="16px" color="#959596" >PROFESOR</Text>
					{/* <Text w="20%" fontFamily="inter" fontWeight="400" fontSize="16px" color="#959596" >HORAS</Text> */}
					<Text w="20%" fontFamily="inter" fontWeight="400" fontSize="16px" color="#959596" >PROGRESO</Text>

				</Flex>

				<Box
					marginTop="15px"
					rounded="50px"
					borderWidth="1px"
					borderColor="light_grey"
				/>

				<Flex
					direction="column"
					flex="1"
					justify="space-between"
				>
					{matriculas?.data?.map((matricula: any, index: number) => (

						<Flex
							direction="column"
							key={index}
						>
							<Flex
								w="100%"
								mt="15px"
								align="center"
							>
								<Flex
									w="40%"
									align="center"
									gap="15px"
								>
									<Image
										boxSize="28px"
										src={`data:image/svg+xml;utf8,${matricula?.grupo?.curso?.icono}`}
									/>
									<Text
										fontSize="14px"
										fontWeight="400"
										mr="10px"
									>
										{`${truncate(matricula?.grupo?.curso?.nombre, { length: 20 })} - ${matricula?.grupo?.nombre}`}
									</Text>
								</Flex>

								<Button
									fontSize="14px"
									fontWeight="400"
									bg={matricula?.estado === EstadoTypeEnum.ACTIVO ? "success_bg" : "fail_bg"}
									rounded="full"
									color={matricula?.estado === EstadoTypeEnum.ACTIVO ? "success_variant" : "fail"}
									_hover={{}}
									cursor="default"
									mr="20px"
								>
									{matricula?.estado === EstadoTypeEnum.ACTIVO ? "Activo" : "Inactivo"}
								</Button>

								<Text
									w="20%"
									fontSize="14px"
									fontWeight="400"
									color="#959596"
								>
									{matricula?.tutor?.nombre}
								</Text>

								{/* <Text
									w="20%"
									fontSize="14px"
									fontWeight="400"
									color="#959596"
								>
									{matricula?.meta?.tiempo_transcurrido === 0
										? "0"
										: (matricula?.meta?.tiempo_transcurrido / 60)?.toFixed(2)
									}
								</Text> */}


								<Flex
									w="20%"
									align="center"
								>
									<Text
										minW="36px"
										fontSize="14px"
										fontWeight="400"
										color="#959596"
									>
										{matricula?.progresoLecciones?.toFixed(0)}%

									</Text>
									<Progress value={matricula?.progresoLecciones} h="6px" rounded="10px" w="100%" sx={{ '& > div': { background: matricula?.progresoLecciones < 75 ? "secondary" : "success" } }} />
								</Flex>

							</Flex>

							<Box marginTop="15px" rounded="50px" borderWidth="1px" borderColor="light_grey" />

						</Flex>
					))}
				</Flex>
			</Flex>
		</Flex>
	);
}
